/* src/App.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(to bottom, #dbeafe, #e0f2fe);
  overflow-x: hidden;
  position: relative;
}

.App {
  text-align: center;
  padding: 2rem;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.cloud {
  position: absolute;
  z-index: 1;
}

.cloud1 {
  top: 10%;
  left: -30%;
  animation: float1 60s linear infinite;
}

.cloud2 {
  top: 30%;
  right: -30%;
  animation: float2 90s linear infinite;
}

@keyframes float1 {
  0% { transform: translateX(0); }
  100% { transform: translateX(130vw); }
}

@keyframes float2 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-130vw); }
}

.App-header {
  margin-bottom: 2rem;
  z-index: 2;
  position: relative;
}

.title {
  font-size: 3rem;
  color: #1e3a8a;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #1e3a8a;
  font-size: 1.1rem;
}

.timeline {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1000px;
  margin: auto;
  z-index: 2;
  position: relative;
}

.step {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  flex: 1 1 200px;
  transition: transform 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
}

.step h2, .step h3 {
  color: #1e3a8a;
}

@media screen and (max-width: 768px) {
  .timeline {
    flex-direction: column;
    gap: 1rem;
  }
}
